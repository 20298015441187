import { createSelector } from "reselect";
import { Matrix3 } from "three";

import getFloor from "../floor_getters/get_floor";
import { computeOriginalBimToForgeViewer } from "../../utilities/threejs_utilities/transform_utilities/photo_area_minimap_to_viewer_transforms";

const identityTransform = new Matrix3();

const getOriginalBimToViewer = createSelector([
  getFloor
], (floor) => {
  if (floor?.offset) {
    return computeOriginalBimToForgeViewer(floor.offset);
  } else {
    return identityTransform;
  }
});

export default getOriginalBimToViewer;
