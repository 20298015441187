import withPromise from "../utilities/general/with_promise";

import type AsBuiltElementsForgeExtension from "./extensions/element_decorators/as_built_elements_forge_extension";
import type AutodeskForgeAdaptor from "./autodesk_forge_adaptor";
import type ColorLib from "tinycolor2";
import type CustomGeometryForgeExtension from "./extensions/element_decorators/custom_geometry_forge_extension";
import type InspectModeInPlaceElementsForgeExtension from "./extensions/element_decorators/inspect_mode_in_place_elements_forge_extension";
import type SimplifiedProjectElementsExtension from "./extensions/element_decorators/simplified_project_elements_forge_extension";
import type ToolbarButtonsExtension from "./extensions/toolbar_buttons_extension";
import type { ColorInput } from "tinycolor2";
import type { ForgeViewer } from "../../components/viewer/forge_viewer/forge_viewer";
import type { PotreeExtension } from "./extensions/potree_forge_extension";
import type { ProjectViewer } from "../../components/project_summary/viewer/project_viewer";

type IForgeViewer = WithExtensions | ProjectViewer;

type CustomColorsExtension = CustomGeometryForgeExtension & { setElementColor: (dbId: number, color: ColorInput) => void }
const isForgeViewer = (viewer: IForgeViewer): viewer is WithExtensions => {
  return (viewer as WithExtensions).progressElementsExtension === null;
};

type WithExtensions = {
  floorPlanRendererExtension: Autodesk.Viewing.Extension;
  asBuiltElementsExtension: AsBuiltElementsForgeExtension;
  cameraControlsExtension: Autodesk.Viewing.Extension;
  toolbarButtonsExtension: Autodesk.Viewing.Extension;
  pdfExtension: Autodesk.Viewing.Extension;
  potreeExtension: PotreeExtension;
  progressElementsExtension: CustomGeometryForgeExtension;
  inspectModeInPlaceElementsExtension: InspectModeInPlaceElementsForgeExtension;
  deviationsAsDesignedElementsExtension: CustomGeometryForgeExtension;
  includedDeviationElementsExtension: CustomGeometryForgeExtension;
  obstructingAsDesignedElementsExtension: CustomGeometryForgeExtension;
  obstructedElementsExtension: CustomGeometryForgeExtension;
  bimColorsExtension: Autodesk.Viewing.Extension;
  reviewedElementsExtension: CustomGeometryForgeExtension;
  customColorsElementsExtension: CustomGeometryForgeExtension & { setElementColor: (dbId: number, color: ColorLib.ColorInput) => void };
}

export default class ExtensionLoader {
  static rejectDelay(reason) {
    const [, reject, loading] = withPromise<never>();
    console.error("Failed to load", reason);
    setTimeout(() => reject(reason), 500);
    return loading;
  }

  private static loadExtension(loadingExtensionFile: Promise<any>, extensionName: string, viewer: AutodeskForgeAdaptor) {
    return loadingExtensionFile.then(() => {
      return viewer.loadExtension(extensionName).catch(ExtensionLoader.rejectDelay);
    }).then(() => {
      return viewer.getExtension(extensionName);
    });
  }

  static loadPotreeExtension(viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) {
    return ExtensionLoader.loadExtension(
      import("./extensions/potree_forge_extension"),
      "PotreeExtension",
      viewer).then((extension) => forgeViewer.potreeExtension = extension as PotreeExtension);
  };

  static loadProgressElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/progress_elements_forge_extension"),
      "Avvir.ProgressElements",
      viewer).then((extension) => forgeViewer.progressElementsExtension = extension as CustomGeometryForgeExtension);
  };

  static loadInspectModeInPlaceElementsExtension = (viewer: AutodeskForgeAdaptor, extensionsRef: {
    inspectModeInPlaceElementsExtension: InspectModeInPlaceElementsForgeExtension
  }) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/inspect_mode_in_place_elements_forge_extension"),
      "Avvir.InspectModeInPlaceElements",
      viewer).then((extension) => extensionsRef.inspectModeInPlaceElementsExtension = extension as InspectModeInPlaceElementsForgeExtension);
  };

  static loadDeviationsAsDesignedElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/deviations_as_designed_elements_forge_extension"),
      "Avvir.DeviationsAsDesignedElements",
      viewer).then((extension) => forgeViewer.deviationsAsDesignedElementsExtension = extension as CustomGeometryForgeExtension);
  };

  static loadAsBuiltElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/as_built_elements_forge_extension"),
      "Avvir.AsBuiltElements",
      viewer).then((extension) => forgeViewer.asBuiltElementsExtension = extension as AsBuiltElementsForgeExtension);
  };

  static loadIncludedDeviationElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/included_deviation_elements_forge_extension"),
      "Avvir.IncludedDeviationElements",
      viewer).then((extension) => forgeViewer.includedDeviationElementsExtension = extension as CustomGeometryForgeExtension);
  };

  static loadObstructingAsDesignedElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/obstructing_as_designed_elements_forge_extension"),
      "Avvir.ObstructingAsDesignedElements",
      viewer).then((extension) => forgeViewer.obstructingAsDesignedElementsExtension = extension as CustomGeometryForgeExtension);
  };

  static loadObstructedElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/obstructed_elements_forge_extension"),
      "Avvir.ObstructedElements",
      viewer).then((extension) => { return forgeViewer.obstructedElementsExtension = extension as CustomGeometryForgeExtension; });
  };

  static loadForgeCameraControlsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: ForgeViewer) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/forge_camera_controls_extension"),
      "Avvir.ForgeCameraControls",
      viewer).then((extension) => {
      forgeViewer.cameraControlsExtension = extension;
    });
  };

  static loadToolbarButtonsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions, enableScreenshotRefactor: boolean) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/toolbar_buttons_extension"),
      "Avvir.ToolbarButtons",
      viewer).then((extension: ToolbarButtonsExtension) => {
      forgeViewer.toolbarButtonsExtension = extension;
      extension.enableRefactor = enableScreenshotRefactor;
    });
  };

  static loadFusionOrbitExtension = (viewer: AutodeskForgeAdaptor) => {
    return viewer.loadExtension("Autodesk.Viewing.FusionOrbit")
      .catch(ExtensionLoader.rejectDelay);
  };

  static loadBoxSelectionExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: IForgeViewer, options) => {
    return viewer.loadExtension("Autodesk.BoxSelection", options)
      .catch(ExtensionLoader.rejectDelay);
  };

  static loadPdfExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return viewer.loadExtension("Autodesk.PDF", viewer).then((extension) => {
      forgeViewer.pdfExtension = extension;
    });
  };

  static loadForgeFloorPlanRendererExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(import("./extensions/forge_floor_plan_renderer_extension"),
      "Avvir.ForgeFloorPlanRenderer",
      viewer).then((extension) => {
      forgeViewer.floorPlanRendererExtension = extension;
    });
  };

  static loadBimColorsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/bim_colors_extension"),
      "Avvir.BimColors",
      viewer).then((extension) => forgeViewer.bimColorsExtension = extension);
  };

  static loadReviewedElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: WithExtensions) => {
    return ExtensionLoader.loadExtension(
      import("./extensions/element_decorators/reviewed_elements_forge_extension"),
      "Avvir.ReviewedElements",
      viewer).then((extension) => forgeViewer.reviewedElementsExtension = extension as CustomGeometryForgeExtension);
  };

  static loadCustomColorsElementsExtension = (viewer: AutodeskForgeAdaptor, extensionsRef: { customColorsElementsExtension: CustomColorsExtension }) => {
    return ExtensionLoader.loadExtension(
        import("./extensions/element_decorators/custom_colors_elements_forge_extension"),
        "Avvir.CustomColorsElements",
        viewer)
      .then((extension) => extensionsRef.customColorsElementsExtension = extension as CustomColorsExtension);
  };

  static loadSimplifiedModelElementsExtension = (viewer: AutodeskForgeAdaptor, forgeViewer: IForgeViewer) => {
    if (isForgeViewer(forgeViewer)) {
      return Promise.resolve();
    } else {
      return ExtensionLoader.loadExtension(
        import("./extensions/element_decorators/simplified_project_elements_forge_extension"),
        "Avvir.SimplifiedModelElementsExtension",
        viewer).then((extension) => forgeViewer.simplifiedModelElementsExtension = extension as SimplifiedProjectElementsExtension);
    }
  };
}

export const AVVIR_CUSTOM_GEOMETRY_EXTENSIONS = [
  "Avvir.ObstructingAsDesignedElements",
  "Avvir.DeviationsAsDesignedElements",
  "Avvir.IncludedDeviationElements",
  "Avvir.ObstructedElements",
  "Avvir.ProgressElements",
  "Avvir.ReviewedElements",
  "Avvir.CustomColorsElements"
];

export const AVVIR_EXTENSIONS = [
  ...AVVIR_CUSTOM_GEOMETRY_EXTENSIONS,
  "Avvir.BimColors",
  "Avvir.ClashDetector",
  "Avvir.TransformControls",
  "Avvir.AsBuiltElements",
  "Avvir.ForgeCameraControls",
  "Avvir.InspectModeInPlaceElements",
  "Avvir.SimplifiedModelElementsExtension",
  "Avvir.ToolbarButtons",
  "Avvir.ForgeFloorPlanRenderer",
  "PotreeExtension",
];
