import type { Action } from "redux";

export type FloorPlanHiddenEvent = Action<typeof FLOOR_PLAN_HIDDEN>

const floorPlanHidden = (): FloorPlanHiddenEvent => ({
  type: FLOOR_PLAN_HIDDEN,
});

export const FLOOR_PLAN_HIDDEN = "floor_plan_hidden";

export default floorPlanHidden;
