import PageNames from "../../../models/domain/enums/page_names";
import PanelType from "../../../models/domain/enums/panel_type";
import { AvvirEvent } from "type_aliases";

export type PanelNodesChangedEvent = AvvirEvent<typeof PANEL_NODES_CHANGED, { page: PageNames, panels: PanelType[] }>

const panelNodesChanged = (page: PageNames, panelNames: PanelType[]): PanelNodesChangedEvent => ({
  type: PANEL_NODES_CHANGED,
  payload: {
    page,
    panels: panelNames
  }
});

export const PANEL_NODES_CHANGED = "panel_nodes_changed";

export default panelNodesChanged;
