import type { Action } from "redux";

export type FloorPlanShownEvent = Action<typeof FLOOR_PLAN_SHOWN>

const floorPlanShown = (): FloorPlanShownEvent => ({
  type: FLOOR_PLAN_SHOWN
});

export const FLOOR_PLAN_SHOWN = "floor_plan_shown";

export default floorPlanShown;
